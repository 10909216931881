/** @format */

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    .console-dropdown ul.ant-dropdown-menu {
        border-radius: 4px;
    }

    .topbar-logout-dropdown .ant-dropdown-menu {
       box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0);
    }

    .ant-modal-close {
       margin-top: 10px;
    }

    #nprogress .bar {
       background: #246EFF;
    }


    .profile-setting-model .ant-modal-close{
        width: initial;
        height: initial;
    }

    .profile-setting-model .ant-modal-close .ant-modal-close-x{
        width: initial;
        height: initial;
    }

    .profile-setting-model .ant-modal .ant-modal-content{
        padding: 25px 32px;
    }

    .profile-setting-model .ant-modal-body {
        padding: 0;
    }
    

    .ant-modal .ant-modal-content{
        padding: 10px 15px;
    }

    .logout-dialog-wrap{
        display: flex;
        align-items: center;
    }

    .logout-dialog-wrap .ant-modal{
        top: 0;
    }

    .logout-dialog-wrap .ant-modal-close{
        width: initial;
        height: initial;
    }

    .logout-dialog-wrap .ant-modal-close .ant-modal-close-x{
        width: initial;
        height: initial;
    }

    .logout-dialog-wrap .ant-modal-header, .profile-setting-model .ant-modal-header{
        padding: 0;
        border-bottom: 0;
    }

    .logout-dialog-wrap .ant-modal-footer {
        border-top: 0;
    }
    
    .Toastify__progress-bar{
       height: 1px;
    }


/* scroll bar css */
html {
  * { 
    scrollbar-width: thin;
  }
}
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background-image: linear-gradient(135deg, #ECECEC 0%, #ECECEC 72%, #ECECEC 100%);
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-image: linear-gradient(135deg, rgba(204, 204, 204, 0.6) 0%, rgba(204, 204, 204, 0.6) 72%, rgba(204, 204, 204, 0.6) 100%);
  transition: all .2s;
  border-radius: 0.4rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(95, 95, 95, 0.7);
}


.leftpanel-scrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background:#fff;
}

.leftpanel-scrollbar::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

.leftpanel-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.transparent-scrollbar::-webkit-scrollbar {
  width: 0px;
  background:transparent;
}

.transparent-scrollbar::-webkit-scrollbar-thumb {
  background:transparent;
}
`;
