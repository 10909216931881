/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import Confirm from "components/shared/Confirm";
import * as env from "constants/env";

let eqrcodeVersion = env.eqrcodeVersion === "true";

const primaryBtnStyle = {
  backgroundColor: "#229BFF",
  borderColor: "#229BFF",
  color: "#ffffff",
  minWidth: "76px",
  fontSize: "14px",
  borderRadius: "2px",
};

const cancelBtnStyle = {
  backgroundColor: "#E5E6EB",
  borderColor: "#E5E6EB",
  color: "#000000",
  width: "76px",
  fontSize: "14px",
  borderRadius: "2px",
};

const Container = styled.div`
  overflow-y: auto;
  max-height: 464px;
  padding: 20px 24px 10px 24px;
  text-align: center;
`;

const StyledTitle = styled.div`
  margin-top: 12px;
  margin-left: 10px;
`;

const StyledDescription = styled.div`
  font-size: 16px;
  color: #2e2e2e;
  font-weight: 600;
`;

interface PropTypes {
  title: string;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
  isVisible: boolean;
}

const LogoutDlg = ({
  title,
  isLoading,
  onClose,
  isVisible,
  onOk,
  ...resProps
}: PropTypes) => {
  const buttons = (
    <>
      <Button
        onClick={() => {
          onClose();
        }}
        style={cancelBtnStyle}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => {
          onOk();
        }}
        loading={isLoading}
        style={primaryBtnStyle}
      >
        Logout
      </Button>
    </>
  );

  return (
    <Confirm
      title={
        <StyledTitle>
          <ExclamationCircleFilled
            style={{ color: "#FF7D00", marginRight: "10px" }}
          />
          {title}
        </StyledTitle>
      }
      onClose={() => {
        onClose();
      }}
      buttons={buttons}
      width={390}
      bodyStyle={{ padding: "0" }}
      maskStyle={{}}
      isVisible={isVisible}
      wrapClassName="logout-dialog-wrap"
      {...resProps}
    >
      <Container>
        <StyledDescription>{`Are you going to logout ${
          eqrcodeVersion ? "Premium Service" : "horizon"
        }?`}</StyledDescription>
      </Container>
    </Confirm>
  );
};

export default LogoutDlg;
