/**
 * /* eslint-disable jsx-a11y/mouse-events-have-key-events
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { path, toUpper } from "ramda";
import { useQuery } from "react-query";

import camera from "assets/camera.svg";
import * as api from "api";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  width: ${(props: { size: any }) => props.size.width};
  height: ${(props: { size: any }) => props.size.height};
`;

const StyledAvatar = styled.div`
  height: ${(props: any) => props.size.height};
  width: ${(props: any) => props.size.width};
  border-radius: 50px;
  background-color: ${(props: any) => props.bgColor || "#cdcdcd"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${(props: any) => props.fontSize};
  overflow: hidden;
`;

const OverLay = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  :hover {
    background: ${(props: any) =>
      props.enableHover
        ? `url(${camera}) no-repeat center rgba(0, 0, 0, 0.5)`
        : ""};
  }
`;

interface Profile {
  firstName?: string | null;
  lastName?: string | null;
  avatarFlag?: boolean;
}

interface UserInfo {
  firstName: string;
  lastName: string;
}

interface Avatar {
  avatarUrl: string;
}

const DefaultAvatar = ({
  size,
  fontSize,
  enableHover,
  profile = { avatarFlag: false },
}: {
  size: any;
  fontSize: string;
  enableHover: boolean;
  profile?: Profile;
}) => {
  const enabled = !!profile.avatarFlag;

  const {
    isLoading: isLoadingavatar,
    data: avatarData,
    isSuccess,
    error,
    isError,
  } = useQuery("userAvatar", api.fetchUserAvatar, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: enabled,
  });

  console.log(avatarData);

  // const profile: Profile = { userInfo: { firstName: "", lastName: "" } };
  const avatar: Avatar = avatarData;
  const userInfo: UserInfo | {} = profile;
  const data = userInfo as UserInfo;
  const [name, setName] = useState("");
  const [bgColor, setBgColor] = useState(""); //"#0088cc"
  const [isLoad, setImageLoad] = useState(false);
  const [isShowDefaultAvatar, setIsShowDefaultAvatar] = useState<boolean>();

  const genBgColor = (value: string) => {
    let tmp = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < value.length; i++) {
      tmp += value[i].charCodeAt(0).toString(16);
    }
    if (tmp.length > 6) {
      tmp = tmp.substr(-6);
    } else if (tmp.length > 3) {
      tmp = tmp.substr(-3);
    } else {
      // tmp = "cdcdcd";
    }
    const color = `#${tmp}`;
    return color;
  };

  useEffect(() => {
    if (!avatarData) {
      setImageLoad(false);
    }
  }, [avatarData]);

  useEffect(() => {
    if (profile) {
      const firstName = path(["firstName"], profile) || "";
      const lastName = path(["lastName"], profile) || "";
      const nameStr: string = `${firstName.substring(0, 1)}${lastName.substring(
        0,
        1
      )}`;
      setName(nameStr ? toUpper(nameStr) : "");
      setBgColor(genBgColor(nameStr));
    }
  }, [profile]);

  const avatarProps = {
    fontSize: fontSize,
    size: size,
    bgColor: (!profile.avatarFlag || isError) && bgColor,
  };

  const overLayProps = { enableHover: enableHover };
  const src = avatar?.avatarUrl;

  useEffect(() => {
    const image = new Image();
    if (src) {
      image.src = src;
      image.onload = function () {
        setImageLoad(true);
      };
    }
  }, [src]);

  return (
    <Container size={size}>
      <StyledAvatar {...avatarProps}>
        {!profile.avatarFlag && name}
      </StyledAvatar>

      {!isShowDefaultAvatar && isLoad && (
        <img
          src={src}
          alt="avatar"
          width={size.width}
          style={{
            borderRadius: "50px",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onError={() => setIsShowDefaultAvatar(true)}
        />
      )}

      <OverLay {...(overLayProps as any)} />
    </Container>
  );
};

DefaultAvatar.defaultProps = {
  profile: {},
  size: {
    width: "",
    height: "",
  },
  fontSize: "",
  enableHover: false,
  avatar: {
    downloadUri: "",
  },
};

export default DefaultAvatar;
