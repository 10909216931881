/** @format */

import React, { useState, useRef, useImperativeHandle, useEffect } from "react";
import styled from "styled-components";
import * as R from "ramda";
import { Form } from "antd";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import "utils/i18n";
import { BlueButton, GreyButton, StyleModal } from "components/shared/common";

const StyledCreateBtn = styled(BlueButton)`
  margin-left: 8px;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  line-height: 28px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface MyFormValues {
  firstName: string;
}

interface ModelProps {
  open?: boolean;
  title: string;
  layout?: any;
  layoutCol?: any;
  wrapClassName?: string;
  onOk?: () => void;
  onSubmit: (values: any, actions: any) => void;
  onCancel?: () => void;
  children?: any;
  footerStyle?: {};
  initialValues?: any;
  primaryName?: string;
  closable?: boolean;
  maskClosable?: boolean;
  destroyOnClose?: boolean;
  validationSchema?: any;
  loading?: boolean;
}

interface FormValues {
  current: {
    getFormikContext: any;
  };
}

const formStyle = { marginTop: "24px", padding: "0" };

const Col = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const CreateModel = React.forwardRef(
  (
    {
      open,
      onOk,
      onSubmit,
      onCancel,
      title,
      layout = "vertical",
      layoutCol = Col,
      initialValues,
      validationSchema,
      children,
      primaryName,
      wrapClassName,
      footerStyle = {},
      closable = false,
      maskClosable = false,
      destroyOnClose = false,
      loading = false,
    }: ModelProps,
    ref: any
  ) => {
    const { t } = useTranslation();

    let formRef = useRef<FormikProps<FormValues>>(null);

    useImperativeHandle(ref, () => ({
      get context() {
        if (formRef.current) {
          return formRef.current;
        }
        return null;
      },
    }));

    return (
      <StyleModal
        title={<StyledTitle>{title}</StyledTitle>}
        open={open}
        closable={closable}
        centered
        maskClosable={maskClosable}
        destroyOnClose={destroyOnClose}
        onCancel={onCancel}
        wrapClassName={wrapClassName}
        footer={null}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            onSubmit(values, actions);
          }}
        >
          {(props: any) => {
            const { handleSubmit, isSubmitting } = props;
            const FormProps = {
              layout: layout,
              onFinish: handleSubmit,
            };
            return (
              <Form {...FormProps} {...layoutCol} style={formStyle}>
                {typeof children === "function" ? children(props) : children}
                <StyledFooter style={footerStyle}>
                  <GreyButton onClick={onCancel}>
                    {t("model.cancel")}
                  </GreyButton>
                  <StyledCreateBtn
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                    loading={loading}
                  >
                    {primaryName || t("model.ok")}
                  </StyledCreateBtn>
                </StyledFooter>
              </Form>
            );
          }}
        </Formik>
      </StyleModal>
    );
  }
);

export default CreateModel;
