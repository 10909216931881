/** @format */

import { initGlobalState, MicroAppStateActions } from "qiankun";

const actions: MicroAppStateActions = initGlobalState({ iptValue: "" });

actions.onGlobalStateChange((state, prev) => {
  // state: after change; prev:  before change
  console.log("main-app onGlobalStateChange", state, prev);
});

// actions.offGlobalStateChange();

export default actions;
