/** @format */

import React, { useReducer, useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyleSheetManager } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import UserContext from "store/createContext";
import initState, { userReducer } from "store/initialState";

import * as api from "authApi";
import Root from "pages";
import { apiBase } from "constants/env";
import { ConfigProvider } from "antd";

function App() {
  const [state, dispatch] = useReducer(userReducer, initState);

  api.init(state, apiBase);

  const queryClient = new QueryClient();

  return (
    <ConfigProvider theme={{ token: { fontFamily: "Roboto" } }}>
      <UserContext.Provider value={{ state, dispatch: dispatch }}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </QueryClientProvider>
      </UserContext.Provider>
    </ConfigProvider>
  );
}

export default App;
