/** @format */

import * as R from "ramda";

import { LooseStaticObject } from "constants/interface";

export const camelToSnack = (str: string) => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const snackToCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, function ($1) {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

var isArray = function isArray(a: any) {
  return Array.isArray(a);
};

var isObject = function isObject(o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    var n: LooseStaticObject = {};

    Object.keys(o).forEach(function (k) {
      n[snackToCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(function (i: any) {
      return keysToCamel(i);
    });
  }

  return o;
};

export const keysToSnack = (o: any) => {
  if (isObject(o)) {
    var n: LooseStaticObject = {};

    Object.keys(o).forEach(function (k) {
      n[camelToSnack(k)] = keysToSnack(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(function (i: any) {
      return keysToSnack(i);
    });
  }

  return o;
};

export const objToQuery = R.curry(function (transfer, obj) {
  return Object.keys(obj)
    .filter(function (key) {
      return !R.isNil(obj[key]);
    })
    .map(function (key) {
      return transfer(key) + "=" + obj[key];
    })
    .join("&");
});

export const getStorageToken = () => {
  const userId: string | null = localStorage.getItem("horizon:last-login-user");
  if (!userId) {
    return false;
  }

  const key = `horizon:user-${userId}-token`;
  const tokens: any | null = localStorage.getItem(key);

  const token = tokens && JSON.parse(tokens);
  return R.path(["token"], token);
};

export const auid = (domain?: string, type?: string, name?: string) =>
  `auid_${domain}_${type}_${name}`.toLowerCase();

export const auidItem = (name: string) => auid("form", "item", name);

export const auidInput = (name: string) => auid("form", "txt", name);

export const auidLabel = (name: string) => auid("form", "lbl", name);

export const auidCheckbox = (name: string) => auid("form", "chk", name);

export const auidRadio = (name: string) => auid("form", "radio", name);

export const auidDate = (name: string) => auid("form", "date", name);

export const auidSelect = (name: string) => auid("form", "dd", name);

export const auidSlider = (name: string) => auid("form", "slide", name);

export const auidOption = (name: string, index: any) =>
  auid("form", "dd", `${name}_option${index}`);
