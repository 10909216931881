/** @format */

export const apiBaseLogin =
  window.REACT_APP_API_BASE || process.env.REACT_APP_API_BASE;
export const apiBase =
  window.REACT_APP_SHELL_API_BASE || process.env.REACT_APP_SHELL_API_BASE;
export const apiConsoleBase =
  window.REACT_APP_CONSOLE_API_BASE || process.env.REACT_APP_CONSOLE_API_BASE;
export const oauthCallback =
  window.REACT_APP_SHELL_OAUTH_CALLBACK ||
  process.env.REACT_APP_SHELL_OAUTH_CALLBACK;
export const clientId =
  window.REACT_APP_SHELL_CLIENT_ID || process.env.REACT_APP_SHELL_CLIENT_ID;
export const TenantId =
  window.REACT_APP_SHELL_TENANT_ID || process.env.REACT_APP_SHELL_TENANT_ID;

export const refreshTokenEndpoint = oauthCallback.replace(
  "callback",
  "refresh"
);

export const debugging = window.location.hostname === "localhost";

export const enablePermission = true;

export const isProduction = window.location.hostname === "console.hpbp.io";

export const REGION_GLOBAL = "GLOBAL";
export const REGION_CHINA = "CHINA";

export const getRegion = () => process.env.REACT_APP_REGION || REGION_GLOBAL;

export const staticSiteEnabled = () =>
  process.env.REACT_APP_REGION === REGION_GLOBAL;

export const horizonDomain =
  process.env.REACT_APP_REGION === REGION_CHINA
    ? "horizon.hp.com.cn"
    : "hpbp.io";

export const horizonAppDomain = "hpbp.io";

export const eqrcodeVersion =
  window.REACT_APP_EQRCODE_VERSION || process.env.REACT_APP_EQRCODE_VERSION;

export const routerName = eqrcodeVersion === "true" ? "ps" : "insights";
