/* eslint-disable import/no-cycle */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode'

const action_login_token = 'login'
const action_refresh_token = 'refresh'

const getStorageData = (key, type = 'string') => {
  const tokenStr = localStorage.getItem(key) || ''
  switch (type) {
    case 'json':
      try {
        return JSON.parse(tokenStr) || {}
      } catch (e) {
        return {}
      }
    case 'number':
      return Number(tokenStr)
    default:
      return tokenStr
  }
}

const updateStorageData = (key, tokenData = '', type = 'string') => {
  switch (type) {
    case 'json':
      localStorage.setItem(key, JSON.stringify(tokenData))
      break
    default:
      localStorage.setItem(key, String(tokenData))
  }
}

const deleteStorageData = (key) => {
  localStorage.removeItem(key)
}

export default (
  { prefix = 'common', clientId, focusLogoutTime = 86400000, redirectUri, customApis, onError },
  api,
) => {
  const last_login_user = `${prefix}:last-login-user`
  const user_login_time = (userId) => `${prefix}:user-${userId}-login-time`
  const user_refresh_time = (userId) => `${prefix}:user-${userId}-refresh-time`
  const user_token = (userId) => `${prefix}:user-${userId}-token`

  let fetchTokenResolve = null
  let tokenObservers = []
  let currentTokenKeeps = 0

  let loginUserId = null

  const getLoginExpireTime = () => {
    const time = focusLogoutTime || Number.MAX_VALUE
    return time
  }

  const fetchTokenApi = async (params, type) => {
    let tokenInfo = null
    try {
      let result = null
      if (type === 'authorization' && customApis && customApis.onGetToken) {
        result = await customApis.onGetToken(params)
      } else if (type === 'refresh') {
        result = await api.fetchRefreshToken(params)
        if (customApis && customApis.onRefreshToken) {
          result = await customApis.onRefreshToken(params)
        }
      } else {
        result = await api.fetchToken(params)
      }

      const { refresh_token, expires_in, access_token } = result || {}

      tokenInfo = {
        refreshToken: refresh_token,
        expiresIn: expires_in,
        token: access_token,
      }

      if (result.code && result.code >= 400) {
        tokenInfo = {
          ...result,
          action: 'logout',
        }
      }
    } catch (e) {
      onError && onError(e)
      try {
        const err = JSON.parse(e) || {}
        tokenInfo = {
          action: 'logout',
          error: err.error_description || err.error || 'Failed to oauth',
        }
      } catch (parseError) {
        if (loginUserId) {
          tokenInfo = {
            action: 'logout',
            ...(getStorageData(user_token(loginUserId), 'json') || {}),
            notRefreshed: true,
          }
        } else {
          tokenInfo = {
            action: 'logout',
            error: 'Token expired, please login again',
          }
        }
      }
    }
    return tokenInfo
  }

  const cleanUserToken = (type = action_refresh_token) => {
    const lastLoginUserId = getStorageData(last_login_user)
    if (type === action_login_token || (lastLoginUserId && lastLoginUserId === loginUserId)) {
      deleteStorageData(last_login_user)
    }
    if (loginUserId) {
      deleteStorageData(user_login_time(loginUserId))
      deleteStorageData(user_refresh_time(loginUserId))
      deleteStorageData(user_token(loginUserId))
     // console.log(`${prefix}: ${loginUserId} cleaned token`)
    }
    loginUserId = null
  }

  const saveOrCleanToken = (result, type = action_refresh_token) => {
    const { error, refreshToken, expiresIn, token, notRefreshed } = result
    if (!error) {
      const currentTime = new Date().getTime()
      const user = jwt_decode(token)
      loginUserId = user.user_id
      if (type === action_login_token) {
        console.log(`user ${loginUserId} login time: ${new Date(currentTime).toString()}`)
        updateStorageData(last_login_user, user.user_id, 'string')
        updateStorageData(user_login_time(loginUserId), currentTime, 'number')
      }
      if (!notRefreshed) {
        updateStorageData(
          user_refresh_time(loginUserId),
          currentTime + parseInt(expiresIn / 2, 0) * 1000,
          'number',
        )
        updateStorageData(
          user_token(loginUserId),
          {
            refreshToken,
            token,
          },
          'json',
        )
        console.log(`${loginUserId} updated token`)
      } else {
        console.log(`not refresh token, still use old token, it may caused by network error`)
      }
    } else {
      cleanUserToken(type)
    }
  }

  const getUser = () => {
    if (loginUserId) {
      const { token } = getStorageData(user_token(loginUserId)) || {}
      if (token) {
        const user = jwt_decode(token)
        return user
      }
    }
    return null
  }

  const fetchLoginToken = async ({ code, state, scope, invitationCode }) => {
    const result = await fetchTokenApi(
      {
        code,
        state,
        scope,
      //  invitation_code: invitationCode,
        redirect_uri: redirectUri,
       // grant_type: 'authorization_code',
       // client_id: clientId,
      },
      'authorization',
    )
    saveOrCleanToken(result, action_login_token)
    return result
  }

  const initLastLogin = async () => {
    console.log(`start to loading last login user info`)
    loginUserId = getStorageData(last_login_user) || null
    if (loginUserId) {
      const currentTime = new Date().getTime()
      const lastLoginTime = getStorageData(user_login_time(loginUserId), 'number') || 0
      const expireTime = getLoginExpireTime()
      console.log(`Last login time is ${new Date(lastLoginTime).toString()}`)
      if (currentTime - lastLoginTime >= expireTime) {
        console.log(
          `token has expired ${parseInt((currentTime - lastLoginTime - expireTime) / 1000, 0)}s`,
        )
        cleanUserToken()
        return {
          error: 'Token expired, please login again',
          loginExpireTime: parseInt((currentTime - lastLoginTime - expireTime) / 1000, 0),
        }
      }

      const nextRefreshTime = getStorageData(user_refresh_time(loginUserId), 'number') || 0
      if (currentTime >= nextRefreshTime) {
        console.log(`start to refresh token`)
        const { refreshToken } = getStorageData(user_token(loginUserId), 'json') || {}

        const result = await fetchTokenApi(
          {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
            client_id: clientId,
          },
          'refresh',
        )
        saveOrCleanToken(result, action_refresh_token)
      } else {
        console.log(`token will refresh after ${(nextRefreshTime - currentTime) / 1000}s`)
      }

      const { token } = getStorageData(user_token(loginUserId), 'json') || {}
      console.log(`last login user id [${loginUserId || ''}]`)
      return {
        token,
        userId: loginUserId,
      }
    }

    return {
      token: '',
      userId: '',
    }
  }

  const getToken = (userId) => {
    const { token } = getStorageData(user_token(userId || loginUserId), 'json') || {}
    return token
  }

  const getAndLockToken = async () => {
    loginUserId = getStorageData(last_login_user)
    if (!loginUserId) {
      return {
        action: 'logout',
        error: 'Token expired, please login again',
      }
    }
    console.log(`get user token [${loginUserId || ''}]`)

    const currentTime = new Date().getTime()
    const lastLoginTime = getStorageData(user_login_time(loginUserId), 'number') || 0
    const expireTime = getLoginExpireTime()
    if (currentTime - lastLoginTime >= expireTime) {
    //  console.log(`Last login time is ${new Date(lastLoginTime).toString()}`)
      console.log(
        `token has expired ${parseInt((currentTime - lastLoginTime - expireTime) / 1000, 0)}s`,
      )
      cleanUserToken()
      return {
        action: 'logout',
        error: 'Token expired, please login again',
        loginExpireTime: parseInt((currentTime - lastLoginTime - expireTime) / 1000, 0),
      }
    }

    if (fetchTokenResolve) {
    //  console.log(`get token after the refresh token job completes`)
      return new Promise((resolve) => {
        tokenObservers.push(({ error, token }) => {
          currentTokenKeeps += 1
       //   console.log(`get token, lock count ${currentTokenKeeps}`)
          resolve({
            error,
            token,
          })
        })
      })
    }

    const nextRefreshTime = getStorageData(user_refresh_time(loginUserId), 'number') || 0
    let result = null
    if (currentTime >= nextRefreshTime) {
    //  console.log(`start to refresh token job`)
      if (currentTokenKeeps > 0) {
      //  console.log(`wait for all apis holding tokens to release the lock`)
        await new Promise((resolve) => {
          fetchTokenResolve = resolve
        })
      } else {
        fetchTokenResolve = () => {
         // console.log(`refresh token now`)
        }
        fetchTokenResolve()
      }
      const { refreshToken } = getStorageData(user_token(loginUserId), 'json') || {}
      result = await fetchTokenApi(
        {
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
          client_id: clientId,
        },
        'refresh',
      )
      if (result.action && result.action === 'logout') {
        return result
      }
      saveOrCleanToken(result, action_refresh_token)
    } else {
    //  console.log(`token will refresh after ${(nextRefreshTime - currentTime) / 1000}s`)
    }
    fetchTokenResolve = null

    const { token } = getStorageData(user_token(loginUserId), 'json') || {}

    tokenObservers.forEach((observer) => {
      observer({
        token,
      })
    })
    tokenObservers = []

    currentTokenKeeps += 1
  //  console.log(`get token, lock count ${currentTokenKeeps}`)
    return {
      ...result,
      token,
    }
  }

  const unlockToken = () => {
    currentTokenKeeps -= 1
    if (currentTokenKeeps < 0) {
      currentTokenKeeps = 0
    }
  //  console.log(`unlock token, lock count ${currentTokenKeeps}`)
    if (currentTokenKeeps === 0) {
   //   console.log(`lock is released`)
      if (fetchTokenResolve) {
   //     console.log(`refresh token job is suspended, awaken job`)
        fetchTokenResolve()
      }
    }
  }

  const cleanLocalData = () => {
    if (!loginUserId) {
      loginUserId = getStorageData(last_login_user) || null
    }
    cleanUserToken()
  }

  const logout = async () => {
    const token = getToken(getStorageData(last_login_user))
    if (token) {
      try {
        await api.revokeToken(token)
      } catch (e) {
        console.log(e)
      }
      cleanLocalData()
    }
  }

  const testRefreshToken = async () => {
    try {
      const { refreshToken } = getStorageData(user_token(loginUserId), 'json') || {}
      await fetchTokenApi(
        {
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
          client_id: clientId,
        },
        'refresh',
      )
    } catch (e) {}
  }

  return {
    fetchLoginToken,
    initLastLogin,
    getUser,
    getToken,
    getAndLockToken,
    unlockToken,
    cleanLocalData,
    logout,
    testRefreshToken,
  }
}
