import React, { Component, Suspense } from "react";
import NProgress from "components/shared/elements/NProgress";
import styled from "styled-components";
import { Button, Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { path } from "ramda";
// import { unstable_batchedUpdates } from "react-dom";

const StyledErrorMsg = styled.div`
  text-align: center;
  color: #f53f3f;
`;

class ErrorBoundary extends Component<
  {},
  { hasError?: boolean; errorMsg: string | undefined }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorMsg: "" };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
    console.log(errorInfo);
    this.setState((state) => {
      return { errorMsg: error };
    });
    // some code here...
  }

  reloadJs() {
    this.setState((state) => {
      return { hasError: false };
    });
  }

  render() {
    const { hasError, errorMsg } = this.state;
    const {
      children,
      fallback = (
        <>
          <Result
            icon={<SmileOutlined />}
            title="Something went wrong!"
            extra={
              <Button type="primary" onClick={this.reloadJs.bind(this)}>
                reload
              </Button>
            }
          />
          <StyledErrorMsg>{path(["message"], errorMsg)}</StyledErrorMsg>
        </>
      ),
    }: any = this.props;

    return hasError ? (
      fallback
    ) : (
      <Suspense fallback={<NProgress />}>{children}</Suspense>
    );
  }
}

export default ErrorBoundary;
