/** @format */

import React, { useContext, lazy, Suspense } from "react";
import styled from "styled-components";
import { message } from "antd";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";

import GlobalStyle from "../globalStyle";
import Context from "store/createContext";
import { REFRESH_TOKEN } from "store/modules/auth";
import { getStorageToken } from "utils/stringUtil";
import { getStorageValue } from "utils/useLocalStorage";
import actions from "components/shared/actions";
import Login from "pages/Login";
// import Oauth from "pages/Login/Oauth";
import Private from "./Private";
import { tokenManager } from "token";
import * as api from "authApi";
import Invitation, { Invitation2 } from "./invitation";
import * as env from "constants/env";
import { routerName } from "constants/env";

// const Login = lazy(() => import("pages/Login"));
const Oauth = lazy(() => import("pages/Login/Oauth"));

const LogoStyled = styled.div`
  height: 50px;
  background: #ffd090;
`;

function Index() {
  let eqrcodeVersion = env.eqrcodeVersion === "true";

  const { state, dispatch } = useContext(Context);
  const authenticated = getStorageToken();
  const solution: string = getStorageValue("horizon:console");
  let targetSolution = "/home";
  if (solution) {
    targetSolution = `/${solution}`;
  }
  if (!eqrcodeVersion) {
    return (
      <>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route
            path="/login"
            element={
              authenticated ? <Navigate to={targetSolution} /> : <Login />
            }
          />
          <Route path="/oauth" element={<Oauth />} />
          <Route
            path="/:project/invitation/*"
            element={!authenticated ? <Invitation /> : <Private />}
          ></Route>
          <Route
            path="/invitation/:project/:invitationId"
            element={<Invitation2 />}
          ></Route>
          <Route
            path="/*"
            element={!authenticated ? <Navigate to="/login" /> : <Private />}
          />
        </Routes>
      </>
    );
  } else {
    return (
      <>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route
            path="/login"
            element={
              authenticated ? <Navigate to={targetSolution} /> : <Login />
            }
          />
          <Route path="/oauth" element={<Oauth />} />
          <Route
            path={`/${routerName}/invitation/*`}
            element={!authenticated ? <Invitation /> : <Private />}
          ></Route>
          <Route
            path="/invitation/:project/:invitationId"
            element={<Invitation2 />}
          ></Route>
          <Route path="/home/*" element={<Navigate to={`/${routerName}`} />} />
          <Route
            path="/console/*"
            element={<Navigate to={`/${routerName}`} />}
          />
          <Route
            path="/*"
            element={!authenticated ? <Navigate to="/login" /> : <Private />}
          />
        </Routes>
      </>
    );
  }
}

export default Index;
