interface RetriesLeftProp {}

export const retryLoad = (fn: any, left?: number, int?: number) => {
  const retriesLeft = left || 5;
  const interval = int || 1000;
  return new Promise<any>((resolve: any, reject: any) => {
    fn.then(resolve).catch((err: any) => {
      setTimeout(() => {
        console.log(546546);
        if (retriesLeft === 1) {
          //
          reject(err);
          // coding...
          console.log(err);
          return;
        }
        retryLoad(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
  });
};
