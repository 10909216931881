/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as R from "ramda";
import type { MenuProps } from "antd";
import { Dropdown, Menu, Spin } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { SettingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "utils/i18n";
import LogoutDlg from "./LogoutDlg";
import UpdateUserModel from "./UpdateUserModel";
import { childProjectName } from "constants/options";
import DefaultAvatar from "components/User/DefaultAvatar";
import ConnerUserInfo from "components/User/ConnerUserInfo";
import ArrowLeft from "assets/ArrowLeft";
import Logo from "assets/SmaillLogo";
import SPTopLogo from "assets/SPTopLogo";
import eqrcode from "assets/eqrcode.png";
import InfoIcon from "assets/TopBar/info";
import TopMenu from "assets/TopBar/TopMenu";
import DevPortal from "assets/TopBar/DevPortal";
import Insights from "assets/TopBar/Insights";
import Logout from "assets/TopBar/Logout";
import { useUpdateData } from "utils/useQueryConfig";
import * as api from "api";
import { setStorageValue } from "utils/useLocalStorage";
import * as env from "constants/env";

let eqrcodeVersion = env.eqrcodeVersion === "true";
const StyledTopBar = styled.div`
  width: 100%;
  height: 60px;
  background: #2e2e2e;
  background-size: 101%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyleLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const StyleLogo = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const StyleNavLink = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  display: inline-block;
`;

const StyledTitle = styled.span`
  margin-left: 10px;
  font-size: 18px;
  color: #cccccc;
  position: relative;
  display: flex;
`;

const HPBPTitle = styled.span`
  /* font-weight: bold; */
  margin-right: 5px;
  padding-right: 5px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  vertical-align: middle;
  margin-right: 20px;
  cursor: pointer;
  display: none;
`;

const StyleLogout = styled.div`
  width: 50px;
  height: 40px;
  line-height: 38px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SignOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dae2e5;
  height: 40px;
  color: #2a3b42;
  cursor: pointer;
  :hover {
    background: #f2f5f6;
  }
`;

const StyleProject = styled.div`
  a {
    display: flex;
    align-items: center;
    height: 40px;
    color: #2e2e2e;
  }
  .projectActive {
    color: #229bff;
    :before {
      position: absolute;
      left: -4px;
      height: 50%;
      content: "";
      border-left: 4px solid #229bff;
    }
  }
`;

const StyleMenuIcon = styled.div`
  height: 25px;
  transition: 0.3s background;
  background: ${(props: any) => props.isHoverMenu && "#4F4F4F"};
  border-radius: ${(props: any) => props.isHoverMenu && "4px"};
`;

const StyleBack = styled.div`
  display: flex;
  align-items: center;
  color: #2e2e2e;
  img {
    margin-right: 8px;
  }
`;

const StyleLogoutDropdown = styled.div`
  background: #fff;
`;

const SignOutMenu = styled.div``;

const infoMenu: MenuProps["items"] = [
  {
    label: (
      <a
        href="https://pages.github.azc.ext.hp.com/hp-business-platform/developers.hpbp.io/quickstart"
        target="_blank"
        rel="noopener noreferrer"
      >
        Documentation
      </a>
    ),
    key: "documentation",
  },
  {
    label: (
      <a
        href="https://pages.github.azc.ext.hp.com/hp-business-platform/home.io/support"
        target="_blank"
        rel="noopener noreferrer"
      >
        Support
      </a>
    ),
    key: "support",
  },
];

const computedProjectClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? "projectActive" : "";

const TopBar = ({ logout }: { logout: any }) => {
  const { t } = useTranslation();

  const [isVisibleHelp, toggleVisibleHelp] = useState(false);
  const [isHoverMenu, toggleHoverMenu] = useState(false);
  const [isVisibleLogout, toggleVisibleLogout] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [isAvatarVisiable, setAvatarVisiable] = useState(false);
  const [userEditor, toggleUserEditor] = useState(false);
  const [errors, setErrors] = useState({});
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    avatarFlag: false,
  });

  const location = useLocation();

  const { mutate, isLoading: updateLoading } = useUpdateData(
    "updateUser",
    (values) => api.updateUser(values)
  );

  const { isLoading, isError, data, refetch } = useQuery(
    "user",
    api.fetchUser,
    {
      // The query will not execute until the userId exists
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setInitialValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        avatarFlag: data.avatarFlag,
      });
    }
  }, [data]);
  const pathname = R.split("/", location.pathname)[1];
  const pageName = childProjectName[pathname as keyof typeof childProjectName];
  const pageNameLink =
    pageName === "Insights" ? "insights/projects" : "console";

  useEffect(() => {
    toggleHoverMenu(false);
  }, [location]);

  const onClose = () => {
    //if (!logoutLoading) {
    toggleVisibleHelp(false);
    toggleVisibleLogout(false);
    //}
  };

  const handleLogout = () => {
    setLogoutLoading(true);
    logout();
  };

  const onHelpOk = () => {
    toggleVisibleHelp(false);
  };

  const onClickLogout: any = (e: any) => {
    if (e.key === "0") {
      toggleVisibleHelp(true);
    }

    if (e.key === "1") {
      toggleVisibleLogout(true);
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    mutate(values, {
      onSuccess(data, variables, context) {
        toggleUserEditor(false);
        refetch();
      },
      onError(e) {
        console.log(e);
      },
    });
  };

  const handleViewUserInfo = () => {
    toggleUserEditor((i) => !i);
  };

  const handleCancel = () => {
    toggleUserEditor(false);
  };

  const setDefaultProject = (solution: string) => {
    // setStorageValue("horizon:console", solution);
  };

  let leftMenu: MenuProps["items"] = [
    {
      label: (
        <StyleProject
          onClick={() => {
            setDefaultProject("console");
          }}
        >
          <NavLink to="/console" className={computedProjectClassName}>
            <DevPortal style={{ marginRight: "8px" }} />
            {t("topBar.leftDropdown.developerPortal")}
          </NavLink>
        </StyleProject>
      ),
      key: "devportal",
    },
    {
      type: "divider",
    },
    {
      label: (
        <StyleProject
          onClick={() => {
            setDefaultProject("insights");
          }}
        >
          <NavLink to="/insights" className={computedProjectClassName}>
            <Insights style={{ marginRight: "8px" }} />
            {t("topBar.leftDropdown.insights")}
          </NavLink>
        </StyleProject>
      ),
      key: "insights",
    },
  ];

  if (pageName) {
    leftMenu = [
      ...leftMenu,
      {
        type: "divider",
      },
      {
        label: (
          <NavLink to="/home">
            <StyleBack>
              <ArrowLeft style={{ marginRight: "8px" }} />
              {t("topBar.leftDropdown.back")}
            </StyleBack>
          </NavLink>
        ),
        key: "home",
      },
    ];
  }

  const menu: any = [
    {
      type: "divider",
    },
    {
      label: (
        <SignOutMenu
          data-auid="AUID_Topbar_User_Setting"
          onClick={handleViewUserInfo}
        >
          <SettingOutlined
            style={{ verticalAlign: "-2px", marginRight: "10px" }}
          />
          {t("topBar.rightDropdown.ProfileSettings")}
        </SignOutMenu>
      ),
      key: "setting",
    },
    {
      type: "divider",
    },
    {
      label: (
        <SignOutMenu
          data-auid="AUID_Topbar_User_Logout"
          onClick={() => toggleVisibleLogout(true)}
        >
          <Logout style={{ marginRight: "10px", verticalAlign: "-3px" }} />
          {t("topBar.rightDropdown.signOut")}
        </SignOutMenu>
      ),
      key: "signOut",
    },
  ];

  const propsMenuIcon = { isHoverMenu: isHoverMenu };

  return (
    <StyledTopBar>
      {eqrcodeVersion ? (
        <div style={{ paddingTop: "2px" }}>
          {/* <img
            src={eqrcode}
            alt="logo"
            width={30}
            style={{ marginLeft: "15px" }}
          /> */}
          <SPTopLogo style={{ marginLeft: "25px" }} />
        </div>
      ) : (
        <StyleLeft>
          <Dropdown
            overlayClassName="console-dropdown"
            menu={{ items: leftMenu }}
            overlayStyle={{ width: "200px" }}
            placement="bottomLeft"
            trigger={["click"]}
            onOpenChange={(e) => {
              toggleHoverMenu(e);
            }}
          >
            <StyleMenuIcon {...propsMenuIcon}>
              <TopMenu />
            </StyleMenuIcon>
          </Dropdown>
          <svg
            style={{ marginLeft: "15px" }}
            width="1"
            height="8"
            viewBox="0 0 1 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.353553"
              y1="-1.5433e-08"
              x2="0.353554"
              y2="8"
              stroke="white"
              strokeWidth="0.707107"
            />
          </svg>
          <StyleLogo>
            <StyleNavLink to="/home" style={{ height: "36px" }}>
              <Logo />
            </StyleNavLink>
            {pageName && (
              <StyledTitle>
                <HPBPTitle>/</HPBPTitle>
                <StyleNavLink to={pageNameLink}>{pageName}</StyleNavLink>
              </StyledTitle>
            )}
          </StyleLogo>
        </StyleLeft>
      )}

      <StyleLogout>
        <Dropdown
          menu={{ items: infoMenu }}
          overlayStyle={{ width: "160px" }}
          overlayClassName="console-dropdown"
          placement="bottomRight"
        >
          <StyledInfoIcon data-auid="AUID_Topbar_Info_Trigger" />
        </Dropdown>
        {/* <StyledWelcomeSpan data-auid="AUID_Topbar_Username">
          {userInfo && userInfo.name && `Hi, ${userInfo.name}`}
        </StyledWelcomeSpan> */}
        <Dropdown
          menu={{ items: menu, onClick: onClickLogout }}
          overlayStyle={{
            top: "57px",
            width: "284px",
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow:
              "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
          }}
          overlayClassName="console-dropdown"
          trigger={["click"]}
          placement="bottomRight"
          onOpenChange={(open) => {
            setAvatarVisiable(open);
          }}
          dropdownRender={(menu) => {
            return (
              <StyleLogoutDropdown className="topbar-logout-dropdown">
                <Spin spinning={!data && isLoading}>
                  <ConnerUserInfo
                    profile={data}
                    handleViewUserInfo={handleViewUserInfo}
                  />
                  {menu}
                </Spin>
              </StyleLogoutDropdown>
            );
          }}
        >
          <span
            style={{ display: "inline-block", cursor: "pointer" }}
            data-auid="AUID_Topbar_User_Trigger"
          >
            <DefaultAvatar
              size={{ height: "24px", width: "24px" }}
              fontSize="9.6px"
              enableHover={false}
              profile={data}
            />
          </span>
        </Dropdown>
      </StyleLogout>
      <UpdateUserModel
        open={userEditor}
        errors={errors}
        initialValues={initialValues}
        loading={updateLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />

      <LogoutDlg
        title="Logout"
        isVisible={isVisibleLogout}
        onClose={onClose}
        onOk={handleLogout}
        isLoading={logoutLoading}
      />
    </StyledTopBar>
  );
};

export default TopBar;
